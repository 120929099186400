<template>
  <div v-if="ticket" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <ticketHistory :ticket="ticket" :closePopup="closePopup" @dismissPopup="dismissPopup" />
  </div>
</template>

<script>

import { showSnackBar } from '@/utilities'

export default {
  components: {
    ticketHistory: () =>
      import(/* webpackChunkName: "ticketHistory" */ "@/components/Popups/ProfilePopups/ticketHistory.vue"),
  },

  data() {
    return {
      // ticketId: "vDV4x1K3j3ZA" "F9Fn1dQpaqUb"
      ticketId: null,
      ticket: null,

      localDisplayLang: null,
    };
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.$nextTick(() => {
      this.ticketId = this.$route.params.ticketId;

      this.ticket = {
        requestid: this.ticketId,
      };
    });
  },

  mounted() {},

  methods: {
    closePopup() {
      this.$router.push("/");
    },

    dismissPopup() {
      this.closePopup();

      // snackbar.show({
      //   text: this.$t("Ticket unavailable"),
      //   textColor: "#ffffff",
      //   pos: "bottom-center",
      //   actionTextColor: "#de2600",
      // });

      showSnackBar(this.$t("Ticket unavailable"), this.$t('DISMISS'));
    },
  },
};
</script>

<style></style>
